import React from 'react';
import { BuyPepe } from './BuyPepe';
import { MarqueeSection } from '../ui/Marquee';
import { BuyPepe2 } from './BuyPepe2';
import { useTranslation } from 'react-i18next';

// const contentArray = [
//   {
//     heading: 'MARKETNG 15%',
//     text: 'PEPPE doesn’t need marketing. But it will do it anyway. BIG TIME.',
//   },
//   {
//     heading: 'STAKING:20%',
//     text: 'Since the rewards have DOUBLED, a thick stack is set aside for staking rewards.',
//   },
//   {
//     heading: 'LIQUIDITY: 15%',
//     text: 'Fat liquidity pools for decentralized exchanges. Pepe in freely.',
//   },
//   {
//     heading: 'PEPPE Fund 30%',
//     text: `Long-term expansion
// Binance?
// Coinbase?
// `,
//   },
//   {
//     heading: 'DEVELOPMENT 15%',
//     text: 'It takes an army to create and manage everything. This ensures it runs smoothly.',
//   },
//   // {
//   //   heading: 'Chain Inventory 10%',
//   //   text: 'An allocation for the $Peppe Layer 2 chain.',
//   // },
// ];

export const Tokenomics = () => {
  const openWhitePaper = () => {
    window.open("/whitepaper.pdf", "_blank");
  }

  const { t } = useTranslation();

  const contentArray = [
    {
      heading: t("marketing_heading"),
      text: t("marketing_text"),
    },
    {
      heading: t("staking_heading"),
      text: t("staking_text"),
    },
    {
      heading: t("liquidity_heading"),
      text: t("liquidity_text"),
    },
    {
      heading: t("peppe_fund_heading"),
      text: t("peppe_fund_text"),
    },
    {
      heading: t("development_heading"),
      text: t("development_text"),
    },
  ];
  return (
    <div className="h-full banner-bg-token">
      {/* Marquee section for large screens */}
      <div className="hidden lg:block">
        <MarqueeSection />
      </div>

      {/* Tokenomics content */}
      <div className="  hidden lg:block  mx-auto overflow-hidden z-[2] lg:px-[15px] relative">
        <div className="content-wrapper mx-auto xl:container py-[40px] md:px-[50px] relative">
          <div className="content hidden lg:flex w-full relative">
            {/* BuyPepe section for large screens */}
            <div className="max-w-[571px] w-full">
              <BuyPepe2 />
            </div>

            {/* Tokenomics details */}
            <div className="hidden lg:block" style={{ width: 'calc(100% - 460px)' }}>
              <div className="about-content pl-[3rem]">
                <h2 className="text-white CCMaladroit font-bold text-[26px] lg:text-[48px] 2xl:text-[48px] mb-[0.5rem]">
                  {t("peppe_tokenomics")}
                </h2>
                <div className="border-[4px] border-black rounded-[32px] py-[40px] px-[30px] bg-[#f9b264]">
                  <h3 className="text-black CCMaladroit text-[24px] mb-4 font-medium">
                    {t("pepf_engine")}
                  </h3>
                  <div className="grid grid-cols-2 2xl:grid-cols-3 gap-[1.5rem]">
                    {contentArray.map((content, index) => (
                      <div key={index} className=" ">
                        <div className="h-full bg-[#fff3] rounded-[16px] p-[15px]">
                          <h4 className="text-black CCMaladroit text-[15px] lg:text-[20px] mb-[0.5rem] font-bold">
                            {content.heading}
                          </h4>
                          <p className="text-black CCMaladroit text-[12px] md:text-[16px]">{content.text}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                  <p className="text-black CCMaladroit font-bold text-[18px] mt-3 lg:text-end mb-0">
                    {t("total_token_amount")}
                  </p>
                  <div className="text-black CCMaladroit font-bold relative text-[18px] underline cursor-pointer" onClick={() => { openWhitePaper() }}>
                    {t("read_pepf_whitepaper")}
                  </div>
                </div>
              </div>
            </div>

            {/* Brain image */}
            {/* <img src="/assets/brain-img.svg" className="brain-img" alt="Brain" /> */}
            {/* <div className="bottom-info3 flex items-center justify-end flex-wrap lg:flex-nowrap">
              <p>
                Then built his own: Pepe Forg. A state-of-the-art Layer 2 solution that rewarded him with freedom…
              </p>
              <img src="/assets/big-brain.gif" className="pepe-tokenomics" alt="" />
            </div> */}
          </div>
        </div>
      </div>

      {/* mobile  */}

      {/* Tokenomics details */}
      <div className=" relative h-full py-5  z-[2] overflow-hidden min-h-[50px]  lg:hidden">
        <div className="about-content  ">
          <h2 className="text-white CCMaladroit px-[15px] font-bold text-[26px] lg:text-[48px] 2xl:text-[48px] mb-[0.5rem]">
            {t("peppe_tokenomics")}
          </h2>
          <div className="px-[15px]">
            <div className="  rounded-[32px] pl-[20px] py-[20px] bg-[#f9b264]" style={{ borderWidth: '1px 1px 4px 1px', borderStyle: 'solid', borderColor: 'black' }}>
              <h3 className="text-black text-[24px] mb-4 font-medium">{t("pepf_engine")}</h3>
              <div className="flex  overflow-hidden overflow-x-auto hidden-scrol">
                {contentArray.map((content, index) => (
                  <div key={index} className=" ">
                    <div className="h-full w-[258px] mx-[5px] bg-[#fff3] rounded-[16px] p-[15px]">
                      <h4 className="text-black text-[15px] lg:text-[20px] mb-[0.5rem] font-bold">{content.heading}</h4>
                      <p className="text-black text-[12px] md:text-[16px]">{content.text}</p>
                    </div>
                  </div>
                ))}
              </div>
              <p className="text-black font-bold text-[18px] mt-3 lg:text-end mb-0">
                {t("total_token_amount")}
              </p>
            </div>
            <button
              className="text-customBlue2 font-bold ml-[15px] pt-[30px] relative text-[18px] w-[50%] underline z-50"
              onClick={() => { openWhitePaper(); }}
            >
              {t("read_pepf_whitepaper")}
            </button>
          </div>
        </div>
        {/* Brain image */}
        <div className="bottom-info3 flex items-center justify-end flex-wrap lg:flex-nowrap">
          <p>Then built his own: PEPPE. A state-of-the-art Layer 2 solution that rewarded him with freedom…</p>
          <img src="/assets/big-brain.gif" className="pepe-tokenomics" alt="" />
        </div>
      </div>
    </div>
  );
};
