import React, { useState } from 'react';
import { BuyPepe } from './BuyPepe';
import { MarqueeSection } from '../ui/Marquee';
import { BuyPepe2 } from './BuyPepe2';
import { useTranslation } from 'react-i18next';

export const HowToBuy = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { t } = useTranslation();


  const accordionItems = [
    { id: 1, title: t("accordion.0.title"), content: t("accordion.0.content") },
    { id: 2, title: t("accordion.1.title"), content: t("accordion.1.content") },
    { id: 3, title: t("accordion.2.title"), content: t("accordion.2.content") }
  ];
  // Toggle the accordion item
  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <div className="h-full banner-bg-buy relative">
      <div className="hidden lg:block">
        <MarqueeSection />
      </div>

      <div className=" hidden lg:block  mx-auto   z-[2] lg:px-[15px]  relative">
        <div className="mx-auto xl:container relative">
          <div className="md:py-[40px] pt-[40px] md:px-[50px] content-wrapper relative      ">
            {/* desktop  */}
            <div className="relative   content hidden lg:flex   items-stretch justify-center flex-col md:flex-row w-full  ">
              <div className="hidden lg:block">
                <BuyPepe2 />
              </div>
              <div className="content hidden lg:block     w-full">
                <div className="flex   flex-wrap lg:flex-nowrap w-full ">
                  <div className="about-content   pl-[3rem]  ">
                    <h2 className="text-white font-[900] 2xl:text-[48px] lg:text-[48px] CCMaladroit text-[26px] mb-[.5rem] ">
                      {t("how_to_buy")}
                    </h2>
                    <div className="w-[593px] p-5  bg-[#f9b264] CCMaladroit rounded-[20px] relative " style={{ borderWidth: '1px 1px 3px 1px', borderStyle: 'solid', borderColor: 'black' }}>
                      {/* <img src="/assets/monitor.svg" className="w-full h-auto" alt="" /> */}
                      <div className=" ">
                        {accordionItems.map((item, index) => (
                          <div className="mb-[20px]" key={item.id}>
                            <div className="flex cursor-pointer" onClick={() => toggleAccordion(index)}>
                              <div className="icon">{item.id}</div>
                              <div className="text-[14px] xl:text-[18px] font-bold ps-[1rem] text-black">
                                {item.title}
                              </div>
                            </div>
                            <div
                              className={`overflow-hidden transition-max-height duration-500 ease-in-out ${activeIndex === index ? 'max-h-[1000px]' : 'max-h-0'
                                }`}
                            >
                              <div className="pt-[10px] pr-[20px] pb-[2px]">
                                <p className="text-black text-[14px] xl:text-[17px]">{item.content}</p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="content  lg:hidden px-[15px]    w-full">
              <div className="flex   flex-wrap lg:flex-nowrap w-full ">
                <div className="about-content     ">
                  <h2 className="text-white CCMaladroit font-[900] 2xl:text-[48px] lg:text-[48px] text-[26px] mb-[.5rem] ">
                    {t("how_to_buy")}
                  </h2>
                  <div className="w-full p-5  bg-[#f9b264] CCMaladroit rounded-[20px] relative " style={{ borderWidth: '1px 1px 3px 1px', borderStyle: 'solid', borderColor: 'black' }}>
                    {/* <img src="/assets/monitor.svg" className="w-full h-auto" alt="" /> */}
                    <div className="">
                      {accordionItems.map((item, index) => (
                        <div className="mb-[20px]" key={item.id}>
                          <div className="flex cursor-pointer" onClick={() => toggleAccordion(index)}>
                            <div className="icon">{item.id}</div>
                            <div className="text-[14px] xl:text-[18px] font-bold ps-[1rem] text-black">
                              {item.title}
                            </div>
                          </div>
                          <div
                            className={`overflow-hidden transition-max-height duration-500 ease-in-out ${activeIndex === index ? 'max-h-[1000px]' : 'max-h-0'
                              }`}
                          >
                            <div className="pt-[10px] pr-[20px] pb-[2px]">
                              <p className="text-black text-[14px] xl:text-[17px]">{item.content}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <img src="/assets/breaking-chains.gif" className="breaking_chain ng-star-inserted" alt="" />
            <div className="callout">First, he broke his chains…</div> */}
          </div>
        </div>
      </div>

      <div className="    lg:hidden relative z-[2]">
        <div className="mx-auto xl:container relative">
          <div className="md:py-[40px] pt-[40px] md:px-[50px] content-wrapper relative  overflow-hidden     ">
            {/* desktop  */}
            <div className="relative   content hidden lg:flex   items-stretch justify-center flex-col md:flex-row w-full  ">
              <div className="hidden lg:block">
                <BuyPepe />
              </div>
              <div className="content hidden lg:block     w-full">
                <div className="flex   flex-wrap lg:flex-nowrap w-full ">
                  <div className="about-content   pl-[3rem]  ">
                    <h2 className="text-white CCMaladroit font-[900] 2xl:text-[48px] lg:text-[48px] text-[26px] mb-[.5rem] ">
                      {t("how_to_buy")}
                    </h2>
                    <div className="w-[593px] p-5  bg-[#f9b264] CCMaladroit rounded-[20px] relative " style={{ borderWidth: '1px 1px 3px 1px', borderStyle: 'solid', borderColor: 'black' }}>
                      {/* <img src="/assets/monitor.svg" className="w-full h-auto" alt="" /> */}
                      <div className="">
                        {accordionItems.map((item, index) => (
                          <div className="mb-[20px]" key={item.id}>
                            <div className="flex cursor-pointer" onClick={() => toggleAccordion(index)}>
                              <div className="icon">{item.id}</div>
                              <div className="text-[14px] xl:text-[18px] font-bold ps-[1rem] text-black">
                                {item.title}
                              </div>
                            </div>
                            <div
                              className={`overflow-hidden transition-max-height duration-500 ease-in-out ${activeIndex === index ? 'max-h-[1000px]' : 'max-h-0'
                                }`}
                            >
                              <div className="pt-[10px] pr-[20px] pb-[2px]">
                                <p className="text-black text-[14px] xl:text-[17px]">{item.content}</p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="content  lg:hidden px-[15px]    w-full">
              <div className="flex   flex-wrap lg:flex-nowrap w-full ">
                <div className="about-content     ">
                  <h2 className="text-white CCMaladroit font-[900] 2xl:text-[48px] lg:text-[48px] text-[26px] mb-[.5rem] ">
                    {t("how_to_buy")}
                  </h2>
                  <div className="w-full p-5  bg-[#f9b264] CCMaladroit rounded-[20px] relative " style={{ borderWidth: '1px 1px 3px 1px', borderStyle: 'solid', borderColor: 'black' }}>
                    {/* <img src="/assets/monitor.svg" className="w-full h-auto" alt="" /> */}
                    <div className="">
                      {accordionItems.map((item, index) => (
                        <div className="mb-[20px]" key={item.id}>
                          <div className="flex cursor-pointer" onClick={() => toggleAccordion(index)}>
                            <div className="icon">{item.id}</div>
                            <div className="text-[14px] xl:text-[18px] font-bold ps-[1rem] text-black">
                              {item.title}
                            </div>
                          </div>
                          <div
                            className={`overflow-hidden transition-max-height duration-500 ease-in-out ${activeIndex === index ? 'max-h-[1000px]' : 'max-h-0'
                              }`}
                          >
                            <div className="pt-[10px] pr-[20px] pb-[2px]">
                              <p className="text-black text-[14px] xl:text-[17px]">{item.content}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <img src="/assets/breaking-chains.gif" className="breaking_chain ng-star-inserted" alt="" />
            <div className="callout">First, he broke his chains…</div> */}
          </div>
        </div>
      </div>
    </div>
  );
};