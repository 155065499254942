import React from 'react';
import Slider from 'react-slick';

const StepSlider = () => {
  const settings = {
    dots: true, // Enable dots
    arrows: false, // Disable arrows
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="slider-container w-full">
      <Slider {...settings} className="space-x-4">
        {/* Card 1 */}
        <div className="box !w-[365px] h-[232px]   rounded-[32px] px-[20px] py-[24px] bg-[#f9b264] flex flex-col justify-between" style={{ borderWidth: '1px 1px 4px 1px', borderStyle: 'solid', borderColor: 'black' }}>
          <h4 className="font-bold text-[24px] text-black">Phase 1: Explosive Launch</h4>
          <ul className="list-disc text-black pl-3">
            <li className="text-[16px] leading-[22.09px] ">
              Token creation on Ethereum.

            </li>
            <li className="text-[16px] leading-[22.09px] ">
              Public presale for true fans.


            </li>
            <li className="text-[16px] leading-[22.09px] ">
              Meme marketing everywhere: <br /> Twitter, TikTok, you name it.
              Phase 2: Peppe Army Assembly


            </li>

            <li className="text-[16px] leading-[22.09px] ">
              Build a community on Telegram, Discord, and Twitter.

            </li>
            <li className="text-[16px] leading-[22.09px] ">
              Crazy airdrops and contests to recruit more frogs.


            </li>
          </ul>
        </div>

        {/* Card 2 */}
        <div className="box !w-[365px] h-[232px]   rounded-[32px] px-[20px] py-[24px] bg-[#f9b264] flex flex-col justify-between" style={{ borderWidth: '1px 1px 4px 1px', borderStyle: 'solid', borderColor: 'black' }}>
          <h4 className="font-bold text-[18px] 2xl:text-[24px] text-black">Step 2 - Coin Eruption</h4>
          <ul className="list-disc text-black pl-3">
            <li className="text-[16px] leading-[22.09px]">
              With a burning desire in his loins, Pepe Forg erupts during presale, offering DOUBLE the staking
              rewards. This is an explosion of Pepe juice all over the blockchain.
            </li>
          </ul>
        </div>
        <div className="box !w-[365px] h-[232px]   rounded-[32px] px-[20px] py-[24px] bg-[#f9b264]  flex flex-col justify-between">
          <h4 className="font-bold text-[18px] 2xl:text-[24px] text-black"> Phase 3: Multiverse Domination
          </h4>
          <ul className="list-disc text-black pl-3">
            <li className="text-[16px] leading-[22.09px] ">
              Listing on DEX platforms like Raydium, Uniswap, etc.


            </li>

          </ul>
        </div>
        {/* Card 3 */}
        <div className="box !w-[365px] h-[232px]   rounded-[32px] px-[20px] py-[24px] bg-[#f9b264]  flex flex-col justify-between">
          <h4 className="font-bold text-[18px] 2xl:text-[24px] text-black"> Phase 4: Final Conquest</h4>
          <ul className="list-disc text-black pl-3">
            <li className="text-[16px] leading-[22.09px] ">
              Listing on Binance?

            </li>
            <li className="text-[16px] leading-[22.09px] ">
              Interactive games and platforms based on the Peppe Multiverse?


            </li>
            <li className="text-[16px] leading-[22.09px] ">
              Global expansion and memes everywhere?

            </li>
          </ul>
        </div>
      </Slider>
    </div>
  );
};

export default StepSlider;
