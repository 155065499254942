import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locales/en.json";
import ZH from "./locales/ZH.json";
import fr from "./locales/fr.json";
import it from "./locales/it.json";
import ja from "./locales/ja.json";
import es from "./locales/es.json";
import tr from "./locales/tr.json";

// Load language from localStorage or default to English
const savedLanguage = localStorage.getItem("language") || "en";

const resources = {
    en: { translation: en },
    ZH: { translation: ZH },
    fr: { translation: fr },
    it: { translation: it },
    ja: { translation: ja },
    es: { translation: es },
    tr: { translation: tr }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: savedLanguage, // Set language from localStorage
        fallbackLng: "en",
        interpolation: { escapeValue: false },
    });

export default i18n;
