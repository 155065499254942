import React, { useState, useEffect } from 'react';
import BarChartComponent from '../ui/BarChart';
import { Modal } from '../ui/Modal';
import { Header } from '../Layout/Header';
// import { AppContext } from '../context/utils';
import { AppContext, getNetworkContract } from '../context/utils';
import { useContext } from 'react';
import { useSwitchChain, useAccount } from 'wagmi';
import { parseUnits, parseEther } from "viem";
import {

  getBalance,
  readContract

} from '@wagmi/core';
import { useConfig } from 'wagmi';
import {
  useReadContract,
  useWriteContract
} from 'wagmi';
import { bnbVaultAbi, ethVaultAbi, presaleAbi } from '../context/abi';
import { bnbVaultAddress, ethUsdtAddress, ethVaultAddress, presaleAddress } from '../context/environment';



const menuItems = [
  { title: 'Staking', href: '/dashboard', active: false },
  { title: 'About', href: '/home#about', active: false },
  { title: 'How To Buy', href: '/home#how-to-buy', active: false },
  { title: 'Tokenomics', href: '/home#tokenomics', active: false },
  { title: 'Roadmap', href: '/home#roadmap', active: false },
  { title: 'FAQs', href: '/home#faqs', active: false },
  { title: 'White Paper', href: 'whitepaper.pdf', target: '_blank' },
];

export const Dashboard = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRewardModalOpen, setIsRewardModalOpen] = useState(false);
  const [isBuyAndStakeOpen, setIsBuyAndStakeOpen] = useState(false);
  const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState('Home');
  const { account, chainId } = useContext(AppContext);
  const [buyWith, setBuyWith] = useState("ETH");
  const [amountToBuy, setamountToBuy] = useState("0");
  const [receiveToken, setReceiveToken] = useState("0");
  const [lowBalanceAlert, setLowBalanceAlert] = useState(false);
  const [endDate, setEndDate] = useState(0);
  const [tokenBalance, setTokenBalance] = useState(null);
  const [stakedTokenAmount, setStakedTokenAmount] = useState(0);
  const [stakedTotalTokenAmount, setStakedTotalTokenAmount] = useState(0);
  const [tokensSoldUser, setTokensSoldUser] = useState(0);
  // const config = useConfig();
  const [buttons, setButtons] = useState(chainId === 1 ? [
    {
      name: 'ETH',
      img: '/assets/ETH.svg',
      active: true,
    },
    {
      name: 'USDT',
      img: '/assets/usdt.svg',
      active: false,
    }
  ] : [
    {
      name: 'BNB',
      img: '/assets/BNB.svg',
      active: true,
    },
    {
      name: 'USDT',
      img: '/assets/usdt.svg',
      active: false,
    }
  ]);
  useEffect(() => {
    if (chainId === 1) {
      setButtons([
        {
          name: 'ETH',
          img: '/assets/ETH.svg',
          active: true,
        },
        {
          name: 'USDT',
          img: '/assets/usdt.svg',
          active: false,
        }
      ]);
    } else {
      setButtons([
        {
          name: 'BNB',
          img: '/assets/BNB.svg',
          active: true,
        },
        {
          name: 'USDT',
          img: '/assets/usdt.svg',
          active: false,
        }
      ]);
    }
  }, [chainId]);
  const { data: usdtReceivedTokenETH, isSuccess: usdtIsSuccessETH, status: usdtStatusETH } = useReadContract({
    address: ethVaultAddress,
    abi: ethVaultAbi,
    functionName: "calculateTokenAmountPay",
    args: [parseEther(amountToBuy?.toString()).toString(), "1"],
    watch: true,
  })
  const { data: ethReceivedToken, isSuccess: ethIsSuccess, status: ethStatus } = useReadContract({
    address: ethVaultAddress,
    abi: ethVaultAbi,
    functionName: "calculateTokenAmountPay",
    args: [parseEther(amountToBuy?.toString()).toString(), "0"],
    watch: true,
  })
  useEffect(() => {
    if (chainId == 1) {
      console.log("---------------------------------------");
      if (usdtIsSuccessETH && buyWith === "USDT") {
        setReceiveToken((Number(usdtReceivedTokenETH) / 10 ** 18).toFixed(2));
      }

    }
  }, [usdtStatusETH, usdtReceivedTokenETH])
  useEffect(() => {
    if (ethIsSuccess && buyWith === "ETH") {
      setReceiveToken((Number(ethReceivedToken) / 10 ** 18).toFixed(2));
      console.log('ethReceivedToken', ethReceivedToken)
    }
  }, [ethStatus, ethReceivedToken])
  // useEffect(()=>{
  //   const getStakedTokenAmount = async ()=>{
  //     const amount = await readContract(config, {
  //       abi:ethVaultAbi,
  //       address:ethUsdtAddress,
  //       functionName:"tokensStakedPerUser",
  //       args:[address]
  //     })
  //     setStakedTokenAmount(Number(amount)/10**18);
  //   }
  //   getStakedTokenAmount();
  // },[])
  useEffect(() => {
    if (!address) {
      return;
    }
    const fetchBalance = async () => {
      let balance;
      if (chainId === 1) {
        if (buyWith !== "USDT") {
          balance = await getBalance(config, {
            address: address
          })
        } else {
          balance = await getBalance(config, {
            address: address,
            token: "0x7169D38820dfd117C3FA1f22a697dBA58d90BA06"
          })
        }
      } else if (chainId === 56) {
        if (buyWith !== "USDT") {
          balance = await getBalance(config, {
            address: address
          })
        } else {

          balance = await getBalance(config, {
            address: address,
            token: "0x55d398326f99059fF775485246999027B3197955"
          })
        }
      }
      else return;
      setTokenBalance(Number(balance?.formatted));
    }
    fetchBalance();
  }, [])




  const {
    status: contractApproveWriteStatus,
    writeContractAsync: contractApproveWriteFunction,
    isLoading: contractApproveLoading,
  } = useWriteContract();
  const {
    writeContract: contractWriteFunction
  } = useWriteContract();
  const { switchChain } = useSwitchChain();
  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleOpenWithdrawModal = () => setIsWithdrawModalOpen(true);
  const handleCloseWithdrawModal = () => setIsWithdrawModalOpen(false);

  const handleOpenRewardModal = () => setIsRewardModalOpen(true);
  const handleCloseRewardModal = () => setIsRewardModalOpen(false);
  const handleOpenBuyAndStakeModal = () => setIsBuyAndStakeOpen(true);
  const handleCloseBuyAndStakeModal = () => setIsBuyAndStakeOpen(false);
  const config = useConfig();
  const { address } = useAccount();
  const buyHandler = () => {
    console.log("Buy handler event!");
  }
  // const claimHandler = () => {
  //   console.log("Claim handler event!");
  // }

  // Function to handle menu item click and update the active state
  const handleMenuClick = (menuItem) => {
    setActiveMenu(menuItem);
  };

  const scrollToSection2 = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const handleInputChange = (e) => {
    const input = e.target.value;
    setamountToBuy(input);
  }
  // const handleButtonClick = async (index) => {
  //   setButtons((prevButtons) =>
  //     prevButtons.map((btn, i) => ({
  //       ...btn,
  //       active: i === index,
  //     })),
  //   );
  //   await fetchTokenBalance();
  //   console.log(buyWith);
  // };
  // const fetchTokenBalance = async () => {
  //   let balance;
  //   if (chainId === 1) {
  //     if (buyWith === "ETH") {
  //       balance = await getBalance(config, {
  //         address: address
  //       })
  //     } else {
  //       balance = await getBalance(config, {
  //         address: address,
  //         token: "0x7169D38820dfd117C3FA1f22a697dBA58d90BA06"
  //       })
  //     }
  //   } else if (chainId === 56) {
  //     if (buyWith === "USDT") {
  //       balance = await getBalance(config, {
  //         address: address
  //       })
  //     } else {

  //       balance = await getBalance(config, {
  //         address: address,
  //         token: "0x55d398326f99059fF775485246999027B3197955"
  //       })
  //     }
  //   }
  //   else return;
  //   console.log(balance);
  //   setTokenBalance(Number(balance?.formatted));
  // }
  const handleCurrencyButtonClick = (currency) => {
    setBuyWith(currency);
  }
  const buyAndStakeHandler = async () => {
    if (!address) {
      return alert("Error! Please connect your wallet.");
    }
    if (!amountToBuy || Number(amountToBuy) <= 0) {
      return alert("Error! Please enter amount to buy.");
    }
    try {
      const networkContractObj = getNetworkContract(chainId);
      // if (chainId == 56) {
      //   if (buyWith === "USDT") {
      //     if (tokenBalance < Number(amountToBuy)) {
      //       setLowBalanceAlert(true);
      //       // alert("You don't have enough balance");
      //       return;
      //     }
      //     await contractApproveWriteFunction(
      //       {
      //         address: networkContractObj.bnbUsdtAddress,
      //         abi: networkContractObj.bnbUsdtAbi,
      //         functionName: "approve",
      //         args: [
      //           networkContractObj?.bnbVaultAddress,
      //           parseUnits(amountToBuy.toString(), 18).toString(),
      //         ]
      //       });
      //     console.log(contractApproveWriteStatus);
      //     console.log(contractApproveLoading)
      //     // contractWriteFunction({
      //     //   address: networkContractObj.bnbVaultAddress,
      //     //   abi: networkContractObj.bnbVaultAbi,
      //     //   functionName: "buyTokenBnbPay",
      //     //   args: ["1", parseUnits(amountToBuy.toString(), 18).toString()],
      //     //   value: "0"
      //     // }
      //     // );
      //   } else {
      //     if (tokenBalance < Number(amountToBuy)) {
      //       setLowBalanceAlert(true);
      //       // alert("You don't have enough balance");
      //       return;
      //     }
      //     contractWriteFunction({
      //       address: networkContractObj.bnbVaultAddress,
      //       abi: networkContractObj.bnbVaultAbi,
      //       functionName: "buyTokenBnbPay",
      //       args: ["0", "0", true],
      //       value: parseUnits(amountToBuy.toString(), 18).toString()
      //     }
      //     );
      //   }
      // } else if 
      // (chainId == 1) {
      if (buyWith === "USDT") {
        if (tokenBalance < Number(amountToBuy)) {
          setLowBalanceAlert(true);
          // alert("You don't have enough balance");
          return;
        }
        contractWriteFunction(
          {
            address: networkContractObj.ethUsdtAddress,
            abi: networkContractObj.ethUsdtAbi,
            functionName: "approve",
            args: [
              networkContractObj?.ethVaultAddress,
              parseUnits(amountToBuy.toString(), 18).toString(),
            ],
          });
        contractWriteFunction(
          {
            address: networkContractObj.ethVaultAddress,
            abi: networkContractObj.ethVaultAbi,
            functionName: "buyTokenEthPay",
            args: ["1", parseUnits(amountToBuy.toString(), 18).toString()],
            value: "0"
          }
        );
      } else {
        if (tokenBalance < Number(amountToBuy)) {
          setLowBalanceAlert(true);
          return;
        }
        contractWriteFunction({
          address: networkContractObj.ethVaultAddress,
          abi: networkContractObj.ethVaultAbi,
          functionName: "buyTokenEthPay",
          args: ["0", "0", true],
          value: parseUnits(amountToBuy.toString(), 18).toString()
        }
        );
        // }
      }
    }
    catch (error) {
      console.log(error);
    }
  }
  const { data, isSuccess, status } = useReadContract({
    address: ethVaultAddress,
    abi: ethVaultAbi,
    functionName: "tokensStakedPerUser",
    args: [address],
    watch: true,
  })
  const { data: totalStakedAmount, isSuccess: isSuccessTotal, status: statusTotal } = useReadContract({
    address: ethVaultAddress,
    abi: ethVaultAbi,
    functionName: "totalStakedAmount",
    args: [],
    watch: true,
  })
  const { data: purchasedTokenData, isSuccess: purchasedTokenIsSuccess, status: purchasedTokenStatus } = useReadContract({
    address: presaleAddress,
    abi: presaleAbi,
    functionName: "tokensSoldPerUser",
    args: [address],
    watch: true,
  })
  useEffect(() => {
    if (isSuccess) {
      setStakedTokenAmount(Number(data) / 10 ** 18);;
    }
    if (isSuccessTotal) {
      setStakedTotalTokenAmount(Number(totalStakedAmount) / 10 ** 18);;
    }
    if (purchasedTokenIsSuccess) {
      setTokensSoldUser(Number(purchasedTokenData) / 10 ** 18);;
    }
  }, [status, data, statusTotal, totalStakedAmount, purchasedTokenStatus, purchasedTokenData])
  const claimHandler = async () => {
    if (chainId == 1) {
      const networkContractObj = getNetworkContract(chainId);
      contractWriteFunction({
        address: networkContractObj.ethVaultAddress,
        abi: networkContractObj.ethVaultAbi,
        functionName: "claimRewards",
        args: [],
        value: "0"
      }
      );
    } else {
      switchChain({ chainId: 1 })
    }
  }
  const withdrawHandler = async () => {
    if (chainId == 1) {
      const networkContractObj = getNetworkContract(chainId);
      contractWriteFunction({
        address: networkContractObj.bnbVaultAddress,
        abi: networkContractObj.bnbVaultAbi,
        functionName: "withdrawStakedTokens",
        args: [],
        value: "0"
      }
      );
    } else {
      switchChain({ chainId: 1 })
    }
  }
  const cardData = [
    {
      title1: 'Staked Balance',
      value1: stakedTokenAmount.toFixed(3),
      unit1: '$Peppe',
      title2: 'Your stakeable',
      value2: (tokensSoldUser - stakedTokenAmount).toFixed(3),
      unit2: '$Peppe',
      buttonText: 'Buy And Stake',
      buttonColor: '#BAD3C1',
      buttonHoverColor: '#c4daca',
      buttonBorderColor: 'transparent',
    },
    {
      title1: '% of Pool',
      value1: '0%',
      title2: 'Total Staked',
      value2: stakedTotalTokenAmount.toFixed(3),
      unit2: '$Peppe',
      infoIcon: '/assets/info-icon.svg',
    },
    {
      title1: 'Estimated Rewards',
      value1: '188%',
      unit1: 'p/a',
      listItems: ['Rewards rate is dynamic', 'Monthly = Rewards % / 12', 'Daily = Rewards % / 365'],
    },
    {
      title1: 'Current Rewards',
      value1: '608.82',
      unit1: 'Per ETH Block',
    },
    {
      title1: 'Total Rewards',
      value1: '0',
      unit1: '$Peppe',
      buttonText: 'Claim Rewards',
      buttonColor: '#BAD3C1',
      buttonHoverColor: '#c4daca',
      buttonBorderColor: 'transparent',
    },
  ];
  const activeButton = buttons.find((btn) => btn.active);
  const label = activeButton.name === 'CARD' ? 'USD' : activeButton.name;
  const img = activeButton.name === 'CARD' ? '/assets/usd.svg' : activeButton.img;
  return (
    <>
      <Header
        activeMenu={activeMenu}
        onMenuClick={handleMenuClick}
        menuItems={menuItems}
        scrollToSection={scrollToSection2}
      />

      <div className="pt-[120px] pb-[100px]">
        <div className="xl:container px-[15px] mx-auto">
          <div className="grid grid-cols-1 items-center lg:grid-cols-2 gap-[1.5rem]">
            <div className="">
              <h3 className="text-black text-[45px] font-bold pb-[1rem] pe-[.5rem] CCMaladroit">Welcome to $Peppe Staking</h3>
              <p className="text-[18px] text-black CCMaladroit">
                The distribution of $Peppe token rewards will occur at a rate of 608.82 $Peppe tokens per ETH block. These
                rewards will be disbursed over 2 years and will be claimable once claim goes live.
              </p>
            </div>
            <div className="flex lg:w-[357px] ml-auto">
              <button
                onClick={handleOpenWithdrawModal}
                className="min-w-[140px] w-full bg-white transition-all    text-black rounded-[80px]    py-[4px] px-[15px] min-h-[40px] ml-auto capitalize text-[16px] font-semibold CCMaladroit " style={{ borderWidth: '1px 1px 6px 1px', borderStyle: 'solid', borderColor: 'black' }}
              >
                Withdraw Staked Tokens
              </button>
            </div>
          </div>
          <div className="mt-5">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-[20px]">
              {cardData.map((card, index) => (
                <div
                  key={index}
                  className="p-[20px] rounded-[20px] CCMaladroit bg-[#f9b264] w-full flex flex-col items-start   "
                  style={{ boxShadow: '0 16px 40px #0c5d9e1a,0 10px 32px #2722241a', borderWidth: '1px 1px 4px 1px', borderStyle: 'solid', borderColor: 'black' }}
                >
                  <div className={`w-full ${card.buttonText && 'h-full'}`}>
                    <div className="flex justify-between w-full">
                      <span className="text-black uppercase mb-1">{card.title1}</span>
                      {card.infoIcon && (
                        <img src={card.infoIcon} className="cursor-pointer" alt="Info Icon" onClick={handleOpenModal} />
                      )}
                    </div>
                    <h2 className="mb-3 mt-1 text-[22px] flex items-start text-[#2eb335] font-bold">
                      {card.value1} <span className="text-[16px] inline-block ms-2 text-[#2eb335]">{card.unit1}</span>
                    </h2>
                  </div>
                  {card.title2 && (
                    <div className="">
                      <span className="text-black uppercase mb-1 inline-block">{card.title2}</span>
                      <h2 className="mb-3 mt-1 text-[22px] flex items-start text-[#2eb335] font-bold">
                        {card.value2} <span className="text-[16px] inline-block ms-2 text-[#2eb335]">{card.unit2}</span>
                      </h2>
                    </div>
                  )}
                  {card.listItems && (
                    <ul className="p-0 mt-3 mb-0">
                      {card.listItems.map((item, i) => (
                        <li key={i} className="flex text-[10px] font-normal mb-[3px] text-[#bad3c1]">
                          <img src="/assets/arrow-icon-right.svg" className="me-1" alt="" />
                          {item}
                        </li>
                      ))}
                    </ul>
                  )}
                  {card.buttonText && (
                    <button
                      onClick={() => {
                        if (card.buttonText === 'Claim Rewards') {
                          // handleOpenRewardModal();
                          claimHandler();
                        } else if (card.buttonText === 'Buy And Stake') {
                          if (chainId === 1) {
                            handleOpenBuyAndStakeModal();
                          } else {
                            switchChain({ chainId: 1 });
                          }
                        }
                      }}
                      className="border-[2px]   flex justify-center items-center border-black rounded-[80px] font-semibold w-full min-w-[120px] min-h-[40px] uppercase text-[1rem] mt-3 bg-[#BAD3C1] hover:bg-[#c4daca] hover:border-transparent transition-all "
                    >
                      {card.buttonText}
                    </button>
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="grid grid-cols-12 gap-[1.5rem] items-center mt-5">
            <div className="col-span-12 lg:col-span-9">
              <div
                className="chart-wraper rounded-[20px]    bg-[#f9b264] min-h-[431px] "
                style={{ boxShadow: '0 6px 5px #00000008', borderWidth: '1px 1px 4px 1px', borderStyle: 'solid', borderColor: 'black' }}
              >
                <div className="flex justify-center px-3">
                  <h4 className="text-white font-normal mb-3 mt-4 text-[14px] ">Total Supply</h4>
                </div>
                <div className="w-full">
                  <BarChartComponent />
                </div>
              </div>
              <div className="mt-2">
                <p className="text-[14px] text-black flex items-center gap-1">
                  Staking powered by
                  <a href="#" className="">
                    <img src="/assets/W3P_Black.svg" className="max-w-[136px] h-auto" alt="" />
                  </a>
                </p>
              </div>
            </div>
            <div className="col-span-12 lg:col-span-3 hidden lg:block">
              <img src="/assets/ai-chip.svg" className="max-w-full  h-auto mx-auto" alt="" />
            </div>
          </div>
        </div>
        {/* Modal */}

        <Modal
          open={isModalOpen}
          onClose={handleCloseModal}
          desc={`   $Peppe staking rewards are distributed at a rate of 608.82 $Peppe per ETH block. Your percentage of the
                pool will determine what percentage of the 608.82 $Peppe tokens you receive.`}
        />
        <Modal open={isRewardModalOpen} onClose={handleCloseRewardModal} desc={'Claim is not live yet'} />

        {isWithdrawModalOpen && (
          <div
            className="fixed z-[999] inset-0 bg-[#74C37B] bg-opacity-70 flex justify-center items-center"
            onClick={handleCloseWithdrawModal}
          >
            <div
              className="bg-[#215bb8]   rounded-[10px] transition-all   min-w-[25em] max-w-lg"
              style={{ boxShadow: '0 10px 10px 1px #0000004d' }}
              onClick={(e) => e.stopPropagation()} // Prevent closing the modal when clicking inside it
            >
              <div className="flex flex-col  items-center w-full">
                <div
                  className="py-[12px] px-[15px] w-full bg-[#0c2b5e]  flex items-center justify-center relative "
                  style={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
                >
                  <h4 className="text-[1rem] text-white font-medium text-center ">Withdraw Staked Tokens</h4>
                  <img
                    src="/assets/close-white.svg"
                    className="absolute right-[20px] w-[12px] h-auto cursor-pointer"
                    onClick={handleCloseWithdrawModal}
                    alt=""
                  />
                </div>
                <div className="py-[12px] px-[15px]">
                  <div className="text-center mb-4">
                    <p className="text-white uppercase mb-[.25rem]  ">Staked Balance</p>
                    <h2 className="text-[25px] text-white font-bold mt-[.5rem] flex justify-center items-start">
                      {stakedTokenAmount.toFixed(3)} <span className="inline-block text-white ms-2 text-[12px] font-bold">$Peppe</span>
                    </h2>
                  </div>
                  <div className="flex gap-[1rem]">
                    <button
                      className="border-[2px]   flex justify-center items-center border-black rounded-[80px] font-semibold w-full min-w-[180px] min-h-[40px] uppercase text-[1rem] mt-3 bg-[#BAD3C1] hover:bg-[#c4daca] hover:border-transparent transition-all "
                      onClick={handleCloseWithdrawModal}
                    >
                      Cancel
                    </button>

                    <button
                      className="    flex justify-center items-center   rounded-[80px] font-semibold w-full min-w-[180px] min-h-[40px] uppercase text-[1rem] mt-3 bg-[#2eb335]  hover:border-transparent transition-all "
                      onClick={withdrawHandler}
                    >
                      Withdraw
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {isBuyAndStakeOpen && (
          <div
            className="fixed z-[999] inset-0 bg-[#74C37B] bg-opacity-70 flex justify-center items-center"
            onClick={handleCloseBuyAndStakeModal}
          >
            <div
              className="bg-[#215bb8]   rounded-[10px] transition-all   min-w-[25em] max-w-lg"
              style={{ boxShadow: '0 10px 10px 1px #0000004d' }}
              onClick={(e) => e.stopPropagation()} // Prevent closing the modal when clicking inside it
            >
              <div className="flex flex-col  items-center w-full">
                <div
                  className="py-[12px] px-[15px] w-full bg-[#0c2b5e]  flex items-center justify-center relative "
                  style={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
                >
                  <h4 className="text-[1rem] text-white font-medium text-center ">Buy and Stake</h4>
                  <img
                    src="/assets/close-white.svg"
                    className="absolute right-[20px] w-[12px] h-auto cursor-pointer"
                    onClick={handleCloseBuyAndStakeModal}
                    alt=""
                  />
                </div>
                <div className="py-[12px] px-[15px]">
                  <div className="relative">

                    <div className="flex gap-2 items-center justify-center">
                      {buttons.map((btn, index) => (
                        <button
                          key={btn.name}
                          onClick={() => { handleCurrencyButtonClick(btn.name) }}
                          className={`uppercase flex justify-center font-semibold leading-1 gap-1 items-center py-[9px] text-[15px] md:text-[23px] min-w-[100px] lg:min-w-[110px] min-h-[40px] rounded-[30px] text-black border-[2px] ${buyWith === btn.name ? 'border-black bg-white' : 'border-transparent bg-[#bad3c180]'
                            } transition-all hover:bg-white hover:border-black`}
                        >
                          <img src={btn.img} className="md:w-[30px] md:h-[30px] w-[26px] h-[26px]" alt={btn.name} />
                          <span>{btn.name}</span>
                        </button>
                      ))}
                    </div>
                    <div className="text-center text-white mt-5 dashTitle">ETH balance {Number(tokenBalance).toFixed(3)}</div>
                    <div className="mt-[1rem] mb-0 ">
                      <div className="mt-[.5rem] ">
                        {/* The input fields */}
                        <div className="grid grid-cols-12 gap-[1rem] xl:my-[1.5rem]">
                          <div className="col-span-6">
                            <div className="flex justify-between items-center mb-1">
                              <label htmlFor="" className="text-[13px] text-[#eaeaea]">
                                Pay with {label}{' '}
                              </label>
                              <div className="text-[13px] text-customGreen2">Max</div>
                            </div>
                            <div className="flex items-center relative">
                              <input
                                type="number"
                                className="min-h-[44px] w-full py-[2px] px-[15px] text-[1rem] font-normal rounded-[30px] bg-transparent outline-none border-[2px] border-white text-[#eaeaea]"
                                placeholder="0"
                                value={amountToBuy}
                                onChange={handleInputChange}
                              />
                              <div
                                className="absolute top-[9px] right-[.8rem] flex items-center"
                                style={{ translate: 'translateY(-50%)' }}
                              >
                                <img src={img} className="w-[28px] h-[28px]" alt={label} />
                              </div>
                            </div>
                          </div>
                          {/* Your second input field (unchanged) */}
                          <div className="col-span-6">
                            <div className="flex justify-between items-center mb-1">
                              <label htmlFor="" className="text-[13px] text-[#eaeaea]">
                                $Peppe You receive
                              </label>
                            </div>
                            <div className="flex items-center relative">
                              <input
                                type="number"
                                className="min-h-[44px] w-full py-[2px] px-[15px] text-[1rem] font-normal rounded-[30px] bg-transparent outline-none border-[2px] border-white text-[#eaeaea]"
                                placeholder="0"
                                value={receiveToken}
                              />
                              <div
                                className="absolute top-[9px] right-[.8rem] flex items-center"
                                style={{ translate: 'translateY(-50%)' }}
                              >
                                <img src="/assets/logo.png" className="w-[28px] h-[28px]" alt="Token" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {lowBalanceAlert ? <p className="text-center text-white text-[14px]  ">You do not have enough balance to pay for this transaction</p> : <></>}
                    <div className="mt-1">
                      <p className="mb-1 text-center"></p>
                    </div>
                  </div>
                  <div className="flex gap-[1rem]">
                    <button
                      className="    flex justify-center items-center   rounded-[80px] font-semibold w-full min-w-[180px] min-h-[40px] uppercase text-[1rem] mt-3 bg-[#2eb335]  hover:border-transparent transition-all "
                      onClick={() => { buyAndStakeHandler() }}
                    >
                      Buy And Stake For 162% Rewards
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

      </div>
    </>
  );
};
