import React from 'react';
import { BuyPepe } from './BuyPepe';
import { MarqueeSection } from '../ui/Marquee';
import StepSlider from '../ui/Slider';
import { BuyPepe2 } from './BuyPepe2';
import { useTranslation } from 'react-i18next';

// const unchain = [
//   {
//     message: 'contact@peppemultiverse.com',
//   },
//   {
//     message: '$PEPPE COUNCIL VOTES',
//   },
//   {
//     message: 'EVERYONE CAN PARTICIPATE',
//   },

// ];

export const FwbComponent = () => {
  const { t } = useTranslation();

  const unchain = [
    { message: t("unchain_1") },
    { message: t("unchain_2") },
    { message: t("unchain_3") }
  ];

  return (
    <div className="h-full bennifit  ">
      {/* Marquee section for large screens */}
      <div className="hidden lg:block">
        <MarqueeSection />
      </div>

      {/* Tokenomics content */}
      <div className="  hidden lg:block  z-[2] mx-auto overflow-hidden lg:px-[15px] relative">
        <div className="mx-auto xl:container relative">
          <div className="content-wrapper py-[40px] md:px-[50px] relative">
            <div className="content hidden lg:flex w-full relative">
              {/* BuyPepe section for large screens */}
              <div className="max-w-[571px] w-full">
                <BuyPepe2 />
              </div>

              {/* Tokenomics details */}
              <div className="hidden lg:block" style={{ width: 'calc(100% - 460px)' }}>
                <div className="about-content pl-[3rem]">
                  <div className="mb-3 flex items-center">
                    <div className="max-w-[665px] w-full">
                      <h2 className="text-white CCMaladroit font-bold text-[26px] lg:text-[40px] 2xl:text-[40px] mb-[0.5rem]">
                        {t("fwb_title")}
                      </h2>
                      <div className="relative">
                        <div className="box box-1 mb-3   text-black  CCMaladroit rounded-[32px] px-[20px] py-[24px] bg-[#f9b264] " style={{ borderWidth: '1px 1px 4px 1px', borderStyle: 'solid', borderColor: 'black' }}>
                          <h4 className="font-bold text-[18px] 2xl:text-[27px] text-black CCMaladroit ">
                            {t("fwb_description")}

                          </h4>
                          <p className=" mb-[1rem] CCMaladroit">
                            {t("fwb_lucky")}
                          </p>

                        </div>
                        {/* <div className=" uppercase CCMaladroit text-[12px] sm:text-[24px] text-black inserted-card ">
                          {' '}
                          “Can Devs Do Something?!”{' '}
                        </div> */}
                      </div>
                      {unchain.map((items, index) => (
                        <div key={index} className="flex mb-[.5rem] items-center ms-1 max-w-[450px] ">
                          <img src="/assets/point.svg" alt="" />
                          <p
                            className="border-[2px] border-black CCMaladroit bg-[#f9b264] rounded-[100px] ms-1 text-black  "
                            style={{ padding: '7px 15px 3px' }}
                          >
                            {items.message}
                          </p>
                        </div>
                      ))}
                      {/* <div className="relative">
                        <div className="boox-one bg-[#f9b264] mt-3 CCMaladroit">
                          <h4 className="font-bold text-[18px] 2xl:text-[27px] text-black mb-3">
                            Here’s how it works:
                          </h4>
                          <div className="flex justify-center lg:justify-start items-center">
                            <p className="p-2 lg:px-3 text-[12px] sm:text-[14px] m-0 bg-[#2eb335] text-white rounded-[11px] ">
                              Build
                            </p>
                            <img src="/assets/arrows.png" className="px-2 w-[72px] h-auto " alt="" />
                            <p className="p-2 lg:px-3 text-[12px] sm:text-[12px] m-0 bg-[#2eb335] text-white rounded-[11px] ">
                              Submit Your Grant Application
                            </p>
                          </div>
                          <div className="flex justify-center lg:justify-start items-center pt-2 pb-4">
                            <p className="p-2 lg:px-3 relative top-[30px] left-[10px] text-[12px] sm:text-[14px] m-0 bg-[#2eb335] text-white rounded-[11px] ">
                              $$$$$
                            </p>
                            <img src="/assets/arrow1.png" className="px-2 w-[72px] h-auto " alt="" />
                            <p className="p-2 lg:px-3 text-[12px] sm:text-[14px] m-0 bg-[#2eb335] text-white rounded-[11px] ">
                              Pepf Council Votes
                            </p>
                            <img src="/assets/arrow2.png" className="px-2 w-[72px] h-auto " alt="" />
                          </div>
                        </div>
                        <div className="pinkBox text-[18px] sm:text-[22px] ">applications open Q4 2024</div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="absolute bottom-[20px] right-0">
          <p className="beni-text">AND REWARDED ALL OF THE BUILDER FROGS FOR BUILDING THE FUTURE</p>
        </div> */}
        {/* <img src="/assets/builder.png" alt="" className="architect" /> */}
      </div>

      {/* mobile  */}

      {/* Tokenomics details */}
      <div className=" relative h-full z-[2] overflow-hidden min-h-[50px]  py-[30px]  lg:hidden">
        <div className="about-content px-[15px]  ">
          <h2 className="text-white CCMaladroit px-[15px] font-[900] leading-[42px] text-[32px]     mb-[0.5rem]">
            {t("how_to_buy")}
          </h2>
          {/* <div className=" uppercase m-8 text-[18px] CCMaladroit sm:text-[24px] text-black inserted-card ">
            {' '}
            “Can Devs Do Something?!”{' '}
          </div> */}
          <div className="relative mt-[50px]">
            <div className="box box-1 mb-3   text-black  rounded-[32px] px-[20px] py-[24px] bg-[#f9b264] " style={{ borderWidth: '1px 1px 4px 1px', borderStyle: 'solid', borderColor: 'black' }}>
              <h4 className="font-bold text-[18px] 2xl:text-[27px] text-black CCMaladroit "> {t("fwb_title")}
              </h4>
              <p className=" mb-[1rem]">
                {t("fwb_title")}
              </p>
              {/* <p className="  ">
                Lucky you! We’re proud to announce the launch of the{' '}
                <span className="font-semibold">{t("how_to_buy")} Collective.</span>
                Here, blockchain developers can build upon the groundbreaking Pepf Unchained L2, and be rewarded for
                their work. Everyone wins
              </p> */}
            </div>
          </div>
          {unchain.map((items, index) => (
            <div key={index} className="flex mb-[.5rem] items-center ms-1 max-w-[450px] ">
              <img src="/assets/point.svg" alt="" />
              <p
                className="border-[2px] border-black CCMaladroit bg-[#f9b264] rounded-[100px] ms-1 text-black  "
                style={{ padding: '7px 15px 3px' }}
              >
                {items.message}
              </p>
            </div>
          ))}
          {/* <div className="relative  ">
            <div className="boox-one mt-3 CCMaladroit">
              <h4 className="font-bold text-[18px] 2xl:text-[27px] text-white mb-3">Here’s how it works:</h4>
              <div className="flex justify-center lg:justify-start items-center">
                <p className="p-2 lg:px-3 text-[12px] sm:text-[14px] m-0 bg-[#2eb335] text-white rounded-[11px] ">
                  Build
                </p>
                <img src="/assets/arrows.png" className="px-2 w-[72px] h-auto " alt="" />
                <p className="p-2 lg:px-3 text-[12px] sm:text-[10px] m-0 bg-[#2eb335] text-white rounded-[11px] ">
                  Submit Your Grant Application
                </p>
              </div>
              <div className="flex justify-center lg:justify-start items-center pt-2 pb-4">
                <p className="p-2 lg:px-3 relative top-[30px] left-[10px] text-[12px] sm:text-[14px] m-0 bg-[#2eb335] text-white rounded-[11px] ">
                  $$$$$
                </p>
                <img src="/assets/arrow1.png" className="px-2 w-[72px] h-auto " alt="" />
                <p className="p-2 lg:px-3 text-[12px] sm:text-[14px] m-0 bg-[#2eb335] text-white rounded-[11px] ">
                  Pepf Council Votes
                </p>
                <img src="/assets/arrow2.png" className="px-2 w-[72px] h-auto " alt="" />
              </div>
            </div>
            <div className="pinkBox text-[18px] sm:text-[22px] ">applications open Q4 2024</div>
          </div> */}
        </div>
        <div className="mt-[60px] relative bottom-bg">
          <p className="reward-text">{t("fwb_reward")}</p>
          {/* <img
            src="/assets/architect.gif"
            className=" architect-2 w-[155px] ml-auto "
            style={{ transform: 'scaleX(-1)' }}
            alt=""
          /> */}
        </div>
      </div>
    </div>
  );
};
