export const header = {
  menuItems: [
    { title: 'Staking', href: '/dashboard', active: false },
    { title: 'About', href: '#about', active: false },
    { title: 'How To Buy', href: '#how-to-buy', active: false },
    { title: 'Tokenomics', href: '#tokenomics', active: false },
    { title: 'Roadmap', href: '#roadmap', active: false },
    { title: 'FAQs', href: '#faqs', active: false },
    { title: 'White Paper', href: 'whitepaper.pdf', target: '_blank' },
  ],
  // languages: [
  //   { name: 'Arabic', image: '/assets/ar.svg', value: 'ar' },
  //   { name: 'Bulgarian', image: '/assets/bg.svg', value: 'bg' },
  //   { name: 'English', image: '/assets/en.svg', value: 'en' }, 
  //   { name: 'Chinese ', image: '/assets/cn.svg', value: 'ZH' }, 
  //   { name: 'Czech', image: '/assets/cz.svg', value: 'cz' },
  //   { name: 'Dutch', image: '/assets/nl.svg', value: 'nl' },
  //   { name: 'French', image: '/assets/fr.svg', value: 'fr' }, 
  //   { name: 'German', image: '/assets/de.svg', value: 'de' },
  //   { name: 'Greek', image: '/assets/el.svg', value: 'el' },
  //   { name: 'Hungarian', image: '/assets/hu.svg', value: 'hu' },
  //   { name: 'Indonesian', image: '/assets/id.svg', value: 'id' },
  //   { name: 'Italian', image: '/assets/it.svg', value: 'it' }, 
  //   { name: 'Japanese', image: '/assets/jp.svg', value: 'ja' }, 
  //   { name: 'Korean', image: '/assets/kr.svg', value: 'kr' },
  //   { name: 'Polish', image: '/assets/pl.svg', value: 'pl' },
  //   { name: 'Portuguese', image: '/assets/pt.svg', value: 'pt' },
  //   { name: 'Romanian', image: '/assets/ro.svg', value: 'ro' },
  //   { name: 'Russian', image: '/assets/ru.svg', value: 'ru' },
  //   { name: 'Slovak', image: '/assets/sk.svg', value: 'sk' },
  //   { name: 'Spanish', image: '/assets/es.svg', value: 'es' },
  //   { name: 'Thai', image: '/assets/th.svg', value: 'th' },
  //   { name: 'Turkish', image: '/assets/tr.svg', value: 'tr' },
  //   { name: 'Vietnamese', image: '/assets/vn.svg', value: 'vn' },
  // ],
  languages: [
    { name: 'English', image: '/assets/en.svg', value: 'en' },
    { name: 'Chinese ', image: '/assets/cn.svg', value: 'ZH' },
    { name: 'French', image: '/assets/fr.svg', value: 'fr' },
    { name: 'Italian', image: '/assets/it.svg', value: 'it' },
    { name: 'Japanese', image: '/assets/jp.svg', value: 'ja' },
    { name: 'Spanish', image: '/assets/es.svg', value: 'es' },
    { name: 'Turkish', image: '/assets/tr.svg', value: 'tr' },

  ]
};


