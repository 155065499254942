import React, { useState } from 'react';
import { BuyPepe } from './BuyPepe';
import { MarqueeSection } from '../ui/Marquee';
import StepSlider from '../ui/Slider';
import { BuyPepe2 } from './BuyPepe2';
import { useTranslation } from 'react-i18next';
// const accordionItems = [
//   {
//     id: 1,
//     title: 'Phase 1: Explosive Launch',

//     items: [
//       'Token creation on Ethereum.',
//       'Public presale for true fans.',
//       'Meme marketing everywhere: Twitter, TikTok, you name it. '
//     ]
//   },
//   {
//     id: 2,
//     title: 'Phase 2: Peppe Army Assembly',

//     items: [
//       'Build a community on Telegram, Discord, and Twitter.',
//       'Crazy airdrops and contests to recruit more frogs.',
//       'Launch staking for early investors'
//     ]
//   },
//   {
//     id: 3,
//     title: 'Phase 3: Multiverse Domination',

//     items: [
//       'Listing on DEX platforms like Raydium, Uniswap, etc.',

//     ]
//   },
//   {
//     id: 4,
//     title: 'Phase 4: Final Conquest',

//     items: [
//       'Listing on Binance?',
//       'Interactive games and platforms based on the Peppe Multiverse?',
//       'Global expansion and memes everywhere?'

//     ]
//   },
// ];
export const RoadMap = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { t } = useTranslation();

  // Toggle the accordion item
  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const accordionItems = [
    { id: 1, title: t("phase_1_title"), items: t("phase_1_items", { returnObjects: true }) },
    { id: 2, title: t("phase_2_title"), items: t("phase_2_items", { returnObjects: true }) },
    { id: 3, title: t("phase_3_title"), items: t("phase_3_items", { returnObjects: true }) },
    { id: 4, title: t("phase_4_title"), items: t("phase_4_items", { returnObjects: true }) }
  ];
  return (
    <div className="h-full banner-bg-token relative">
      {/* Marquee section for large screens */}
      <div className="hidden lg:block">
        <MarqueeSection />
      </div>

      {/* Tokenomics content */}
      <div className="  hidden lg:block z-[2]  mx-auto overflow-hidden lg:px-[15px] relative">
        <div className="mx-auto xl:container relative">
          <div className="content-wrapper py-[40px] md:px-[50px] relative">
            <div className="content hidden lg:flex w-full relative">
              {/* BuyPepe section for large screens */}
              <div className="max-w-[571px] w-full">
                <BuyPepe2 />
              </div>

              {/* Tokenomics details */}
              <div className="hidden lg:block" style={{ width: 'calc(100% - 460px)' }}>
                <div className="w-[450px] mx-auto p-5  bg-[#f9b264] CCMaladroit rounded-[20px] relative " style={{ borderWidth: '1px 1px 3px 1px', borderStyle: 'solid', borderColor: 'black' }}>
                  {/* <img src="/assets/monitor.svg" className="w-full h-auto" alt="" /> */}
                  <div className=" ">
                    {accordionItems.map((item, index) => (
                      <div className="mb-[20px]" key={item.id}>
                        <div className="flex cursor-pointer" onClick={() => toggleAccordion(index)}>
                          <div className="icon">{item.id}</div>
                          <div className="text-[14px] xl:text-[18px] font-bold ps-[1rem] text-black">
                            {item.title}
                          </div>
                        </div>
                        <div
                          className={`overflow-hidden transition-max-height duration-500 ease-in-out ${activeIndex === index ? 'max-h-[1000px]' : 'max-h-0'
                            }`}
                        >
                          <ul className="text-black list-disc pl-8">
                            {item.items.map((item, itemIndex) => (
                              <li key={itemIndex} className="text-[17px] lg:text-[17px]">
                                {item}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* mobile  */}

      {/* Tokenomics details */}
      <div className=" relative h-full z-[2] py-[40px] overflow-hidden min-h-[50px]  lg:hidden">
        <div className="about-content  ">
          <h2 className="text-white CCMaladroit px-[15px] font-[900] leading-[42px] text-[35px]     mb-[0.5rem]">
            $PEPPE                   {t("roadmap")}

          </h2>
          <div className="w-[450px] mx-auto p-5  bg-[#f9b264] CCMaladroit rounded-[20px] relative " style={{ borderWidth: '1px 1px 3px 1px', borderStyle: 'solid', borderColor: 'black' }}>
            {/* <img src="/assets/monitor.svg" className="w-full h-auto" alt="" /> */}
            <div className=" ">
              {accordionItems.map((item, index) => (
                <div className="mb-[20px]" key={item.id}>
                  <div className="flex cursor-pointer" onClick={() => toggleAccordion(index)}>
                    <div className="icon">{item.id}</div>
                    <div className="text-[14px] xl:text-[18px] font-bold ps-[1rem] text-black">
                      {item.title}
                    </div>
                  </div>
                  <div
                    className={`overflow-hidden transition-max-height duration-500 ease-in-out ${activeIndex === index ? 'max-h-[1000px]' : 'max-h-0'
                      }`}
                  >
                    <ul className="text-black list-disc pl-8">
                      {item.items.map((item, itemIndex) => (
                        <li key={itemIndex} className="text-[17px] lg:text-[17px]">
                          {item}
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* <div className="bottom-info4 flex items-end">
            <img src="/assets/chians.gif" className="img-fluid pepe-roadmap mb-3" alt="" />
            <p>And rewarded his legions of fans with endless, marvelous gainz…</p>
          </div> */}
        </div>
      </div>
    </div>
  );
};
