import React, { useState } from 'react';

const Accordion = ({ accordionData }) => {
  const [openIndex, setOpenIndex] = useState(0);

  const handleClick = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="accordion h-full w-full">
      {accordionData.map((faq, index) => (
        <div
          key={index}
          className="  rounded-[16px] mb-[20px] px-[20px] CCMaladroit py-[24px] bg-[#f9b264] max-w-[500px] mx-auto w-full"
          style={{ boxShadow: '4px 4px #000', borderWidth: '1px 1px 3px 1px', borderStyle: 'solid', borderColor: 'black' }}
        >
          <div className="cursor-pointer text-[20px] font-bold text-black" onClick={() => handleClick(index)}>
            {faq.title}
          </div>
          {/* Content */}
          <div
            className={`transition-max-height duration-500 ease-in-out overflow-hidden overflow-y-auto ${openIndex === index ? 'max-h-[200px]' : 'max-h-0'
              }`}
          >
            <div className="mt-3">
              <span className="text-[17px] inline-block CCMaladroit text-black mb-2">{faq.description}</span>
              {faq.description2 &&
                <span className="text-[17px] inline-block CCMaladroit text-black mb-2">{faq.description2}</span>
              }
              {faq.items.length > 0 && (
                <ul className="text-black list-disc pl-5">
                  {faq.items.map((item, itemIndex) => (
                    <li key={itemIndex} className="text-[17px] lg:text-[17px]">
                      {item}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Accordion;
