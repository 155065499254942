import React from 'react';
import Marquee from 'react-fast-marquee';
import { useTranslation } from 'react-i18next';


export const MarqueeSection = () => {
  const { t } = useTranslation();

  const message = [
    { name: t("msg_pepe_l2_launch") },
    { name: t("msg_pepe_layer2") },
    { name: t("msg_peppe_blockchain") },
    { name: t("msg_presale_rising") },
    { name: t("msg_experts_say") },
    { name: t("msg_meme_ecosystem") },
    { name: t("msg_meme_ecosystem") },
    { name: t("msg_pepe_l2_launch") },
    { name: t("msg_pepe_layer2") }
  ];
  return (
    <div className="fixed lg:sticky z-10 lg:z-[2]  w-full">
      <div
        className="min-h-[34px] flex text-white relative border-[3px] border-black z-[3] left-0 right-0 bg-[#ed323d] overflow-hidden"
        style={{ padding: '7px 0 5px 31px' }}
      >
        <div className="min-w-[100px]">
          <h3 className="uppercase md:text-[20px] text-[18px] text-white text-nowrap">{t("breaking")}</h3>
        </div>
        <div className="relative flex-1">
          {/* Gradient effect using pseudo-element */}
          <div className="absolute inset-0 pointer-events-none">
            <div className="absolute inset-y-0 left-0 w-1/5 lg:bg-gradient-to-r from-[#ed323d] via-[#ed323d00] to-[#ed323d00] z-10"></div>
            <div className="absolute inset-y-0 right-0 w-1/4 bg-gradient-to-l from-[#ed323d] via-[#ed323d00] to-[#ed323d00] z-10"></div>
          </div>
          <Marquee>
            <div className="flex items-center">
              {message.map((message, index) => (
                <h4 key={index} className="text-[20px] text-white inline-block px-4">
                  {index > 0 && '|'}
                  <span className="px-4">{message.name}</span>
                </h4>
              ))}
            </div>
          </Marquee>
        </div>
      </div>
    </div>
  );
};
