import React, { useContext, useEffect, useState } from 'react';
import { Modal } from '../ui/Modal';
import { useAccount, useSwitchChain, useDisconnect } from 'wagmi';
import { AppContext, getNetworkContract } from '../context/utils';
import { parseUnits, parseEther } from "viem";
import {
    useReadContract,
    useWriteContract
} from 'wagmi';
import CountItem from '../countItem';
import Countdown from "react-countdown";
import {
    getBalance,
    readContract,
} from '@wagmi/core';
import { bnbVaultAddress, ethVaultAddress, presaleAddress } from '../context/environment';
import { bnbVaultAbi, ethVaultAbi, presaleAbi } from '../context/abi';
import { useConfig } from 'wagmi';
import { BuyPepeModal } from '../ui/BuyPepeModal';
import { toast } from "react-toastify";
import { writeContract } from '@wagmi/core';
import { waitForTransaction } from '@wagmi/core';
import { useTranslation } from 'react-i18next';
export const BuyPepe2 = () => {
    const { t } = useTranslation();


    const testTotalBalance = 200000;
    const testBalance = testTotalBalance.toLocaleString();

    const [lowBalanceAlert, setLowBalanceAlert] = useState(false);
    const [tokenBalance, setTokenBalance] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalOpen2, setIsModalOpen2] = useState(false);
    const [isModalOpen3, setIsModalOpen3] = useState(false);
    const [isModalOpen4, setIsModalOpen4] = useState(false);
    const [receiveToken, setReceiveToken] = useState("0");
    const [amountToBuy, setamountToBuy] = useState("0");
    const [tokensSold, setTokensSold] = useState(0);
    const [tokenTotalBalance, setTokenTotalBalance] = useState(0);
    const [buyWith, setBuyWith] = useState("BNB");
    const [endDate, setEndDate] = useState(0);
    const [purchasedToken, setPurchasedToken] = useState(0);

    const handleOpenModal4 = () => setIsModalOpen4(true);
    const handleCloseModal4 = () => setIsModalOpen4(false);
    const { address } = useAccount();
    const { switchChain } = useSwitchChain();
    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);
    const handleOpenModal2 = () => setIsModalOpen2(true);
    const handleCloseModal2 = () => setIsModalOpen2(false);
    const handleOpenModal3 = () => setIsModalOpen3(true);
    const handleCloseModal3 = () => setIsModalOpen3(false);
    const { account, chainId } = useContext(AppContext);
    const testdate = 1734726476000;
    const [buttons, setButtons] = useState(chainId === 1155111 ? [
        {
            name: 'ETH',
            img: '/assets/ETH.svg',
            active: true,
        },
        {
            name: 'USDT',
            img: '/assets/usdt.svg',
            active: false,
        },
        {
            name: 'CARD',
            img: '/assets/card.svg',
            active: false,
        },
    ] : [
        {
            name: 'BNB',
            img: '/assets/BNB.svg',
            active: true,
        },
        {
            name: 'USDT',
            img: '/assets/usdt.svg',
            active: false,
        }
    ]);
    const {
        writeContractAsync: contractWriteFunction
    } = useWriteContract();
    const {
        status: contractApproveWriteStatus,
        writeContractAsync: contractApproveWriteFunction,
        isLoading: contractApproveLoading,
    } = useWriteContract();

    const renderer = ({
        days,
        hours,
        minutes,
        seconds,
    }) => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between"
                }}
            >
                <CountItem
                    title="Days"
                    value={`${days >= 10 ? days.toString() : "0" + days.toString()}`}
                ></CountItem>

                <CountItem
                    title="Hours"
                    value={`${hours >= 10 ? hours.toString() : "0" + hours.toString()}`}
                ></CountItem>

                <CountItem
                    title="Minutes"
                    value={`${minutes >= 10 ? minutes.toString() : "0" + minutes.toString()
                        }`}
                ></CountItem>

                <CountItem
                    title="Seconds"
                    value={`${seconds >= 10 ? seconds.toString() : "0" + seconds.toString()
                        }`}
                ></CountItem>
            </div>
        );
    };
    const config = useConfig();
    const { data, isSuccess, status } = useReadContract({
        address: bnbVaultAddress,
        abi: bnbVaultAbi,
        functionName: "calculateTokenAmountPay",
        args: [parseEther(amountToBuy?.toString()).toString(), "0"],
        watch: true,
    })
    const { data: purchasedTokenData, isSuccess: purchasedTokenIsSuccess, status: purchasedTokenStatus } = useReadContract({
        address: presaleAddress,
        abi: presaleAbi,
        functionName: "tokensSoldPerUser",
        args: [address],
        watch: true,
    })
    const { data: tokensSoldData, isSuccess: tokensSoldIsSuccess, status: tokensSoldStatus } = useReadContract({
        address: presaleAddress,
        abi: presaleAbi,
        functionName: "totalTokensSold",
        args: [],
        watch: true,
    })
    const { data: tokenBalanceData, isSuccess: tokenBalanceIsSuccess, status: tokenBalanceStatus } = useReadContract({
        address: presaleAddress,
        abi: presaleAbi,
        functionName: "getTokenBalance",
        args: [],
        watch: true,
    })
    const { data: usdtReceivedToken, isSuccess: usdtIsSuccess, status: usdtStatus } = useReadContract({
        address: bnbVaultAddress,
        abi: bnbVaultAbi,
        functionName: "calculateTokenAmountPay",
        args: [parseEther(amountToBuy?.toString()).toString(), "1"],
        watch: true,
    })
    const { data: usdtReceivedTokenETH, isSuccess: usdtIsSuccessETH, status: usdtStatusETH } = useReadContract({
        address: ethVaultAddress,
        abi: ethVaultAbi,
        functionName: "calculateTokenAmountPay",
        args: [parseEther(amountToBuy?.toString()).toString(), "1"],
        watch: true,
    })
    const { data: ethReceivedToken, isSuccess: ethIsSuccess, status: ethStatus } = useReadContract({
        address: ethVaultAddress,
        abi: ethVaultAbi,
        functionName: "calculateTokenAmountPay",
        args: [parseEther(amountToBuy?.toString()).toString(), "0"],
        watch: true,
    })
    const testEndDate = 1726231769000;
    useEffect(() => {
        if (isSuccess && buyWith === "BNB") {
            setReceiveToken((Number(data) / 10 ** 18).toFixed(2));
        }
    }, [status, data])
    useEffect(() => {
        if (purchasedTokenIsSuccess) {
            setPurchasedToken((Number(purchasedTokenData) / 10 ** 18).toFixed(2));
        }
    }, [purchasedTokenStatus, purchasedTokenData])
    useEffect(() => {
        if (tokensSoldIsSuccess) {
            setTokensSold(Number(tokensSoldData));
        }
    }, [tokensSoldStatus, tokensSoldData])
    useEffect(() => {
        if (tokenBalanceIsSuccess) {
            setTokenTotalBalance(Number(tokenBalanceData));
        }
    }, [tokenBalanceStatus, tokenBalanceData])
    useEffect(() => {
        if (chainId == 1) {
            if (usdtIsSuccessETH && buyWith === "USDT") {
                setReceiveToken((Number(usdtReceivedTokenETH) / 10 ** 18).toFixed(2));
            }
        } else {
            if (usdtIsSuccess && buyWith === "USDT") {
                setReceiveToken((Number(usdtReceivedToken) / 10 ** 18).toFixed(2));
            }
        }
    }, [usdtStatus, usdtReceivedToken, usdtStatusETH, usdtReceivedTokenETH])
    useEffect(() => {
        if (ethIsSuccess && buyWith === "ETH") {
            setReceiveToken((Number(ethReceivedToken) / 10 ** 18).toFixed(2));

            // console.log('ethReceivedToken', ethReceivedToken)

        }
    }, [ethStatus, ethReceivedToken])
    useEffect(() => {
        setReceiveToken("0");
        setamountToBuy("0");
    }, [buyWith])
    useEffect(() => {
        const getReadData = async () => {
            const endDateStamp = await readContract(config, {
                abi: presaleAbi,
                address: presaleAddress,
                functionName: "endSaleDate"
            })
            setEndDate(Number(endDateStamp) * 1000);
        }
        if (chainId === 1) {
            getReadData();
        }
        switchChain({
            chainId: 1
        });
    }, [])
    useEffect(() => {
        const getReadData = async () => {
            const endDateStamp = await readContract(config, {
                abi: presaleAbi,
                address: presaleAddress,
                functionName: "endSaleDate"
            })
            setEndDate(Number(endDateStamp) * 1000);
        }
        if (chainId === 1) {
            getReadData();
        }
    }, [chainId])
    useEffect(() => {
        if (chainId != 1) {
            switchChain({
                chainId: 1
            });
        }
    }, []);
    useEffect(() => {
        if (chainId === 1) {
            setButtons([
                {
                    name: 'ETH',
                    img: '/assets/ETH.svg',
                    active: true,
                },
                {
                    name: 'USDT',
                    img: '/assets/usdt.svg',
                    active: false,
                },
                {
                    name: 'CARD',
                    img: '/assets/card.svg',
                    active: false,
                },
            ]);
        } else {
            setButtons([
                {
                    name: 'BNB',
                    img: '/assets/BNB.svg',
                    active: true,
                },
                {
                    name: 'USDT',
                    img: '/assets/usdt.svg',
                    active: false,
                }
            ]);
        }
    }, [chainId]);
    useEffect(() => {
        if (!address) {
            return;
        }
        const fetchBalance = async () => {
            let balance;
            if (chainId === 1) {
                if (buyWith !== "USDT") {
                    balance = await getBalance(config, {
                        address: address
                    })
                } else {
                    balance = await getBalance(config, {
                        address: address,
                        token: "0x7169D38820dfd117C3FA1f22a697dBA58d90BA06"
                    })
                }
            } else if (chainId === 56) {
                if (buyWith !== "USDT") {
                    balance = await getBalance(config, {
                        address: address
                    })
                } else {

                    balance = await getBalance(config, {
                        address: address,
                        token: "0x55d398326f99059fF775485246999027B3197955"
                    })
                }
            }
            else return;
            setTokenBalance(Number(balance?.formatted));
        }
        fetchBalance();
    }, [])
    useEffect(() => {
        if (chainId === 56) {
            setBuyWith("BNB")
        } else if (chainId === 1) {
            setBuyWith("ETH")
        }
    }, [chainId])
    const handleButtonClick = async (index) => {
        setButtons((prevButtons) =>
            prevButtons.map((btn, i) => ({
                ...btn,
                active: i === index,
            })),
        );
        await fetchTokenBalance();
        console.log(buyWith);
    };
    const fetchTokenBalance = async () => {
        let balance;
        if (chainId === 1) {
            if (buyWith === "USDT") {
                balance = await getBalance(config, {
                    address: address
                })
            } else {
                balance = await getBalance(config, {
                    address: address,
                    token: "0x7169D38820dfd117C3FA1f22a697dBA58d90BA06"
                })
            }
        } else if (chainId === 56) {
            if (buyWith === "USDT") {
                balance = await getBalance(config, {
                    address: address
                })
            } else {

                balance = await getBalance(config, {
                    address: address,
                    token: "0x55d398326f99059fF775485246999027B3197955"
                })
            }
        }
        else return;
        console.log(balance);
        setTokenBalance(Number(balance?.formatted));
    }
    const handleInputChange = (e) => {
        const input = e.target.value;
        setLowBalanceAlert(false);
        setamountToBuy(input);
    }
    const buyHandler = async () => {
        if (!address) {
            toast.error("Please connect your wallet.");
            return;
        }
        if (!amountToBuy || Number(amountToBuy) <= 0) {
            toast.error("Please enter amount to buy.");
            return;
        }
        try {
            const networkContractObj = getNetworkContract(chainId);
            if (chainId == 56) {
                if (buyWith === "USDT") {
                    if (tokenBalance < Number(amountToBuy)) {
                        setLowBalanceAlert(true);
                        return;
                    }
                    const hash = await contractApproveWriteFunction(
                        {
                            address: networkContractObj.bnbUsdtAddress,
                            abi: networkContractObj.bnbUsdtAbi,
                            functionName: "approve",
                            args: [
                                networkContractObj?.bnbVaultAddress,
                                parseUnits(amountToBuy.toString(), 18).toString(),
                            ]
                        });

                    const receipt = await waitForTransaction(config, { hash: hash });
                    if (receipt.status === "success") {
                        toast.success("User approved")
                    }
                    const buyHash = await contractWriteFunction({
                        address: networkContractObj.bnbVaultAddress,
                        abi: networkContractObj.bnbVaultAbi,
                        functionName: "buyTokenBnbPay",
                        args: ["1", parseUnits(amountToBuy.toString(), 18).toString()],
                        value: "0"
                    }
                    );

                    const buyReceipt = await waitForTransaction(config, { hash: buyHash });
                    if (buyReceipt.status === "success") {
                        toast.success("User bought token successfully");
                    }

                } else {
                    if (tokenBalance < Number(amountToBuy)) {
                        setLowBalanceAlert(true);
                        return;
                    }
                    const buyHash = await contractWriteFunction({
                        address: networkContractObj.bnbVaultAddress,
                        abi: networkContractObj.bnbVaultAbi,
                        functionName: "buyTokenBnbPay",
                        args: ["0", "0"],
                        value: parseUnits(amountToBuy.toString(), 18).toString()
                    }
                    );
                    const buyReceipt = await waitForTransaction(config, { hash: buyHash });
                    if (buyReceipt.status === "success") {
                        toast.success("User bought token successfully");
                    }
                }
            } else if (chainId == 1) {
                console.log(tokenBalance);
                if (buyWith === "USDT") {
                    if (tokenBalance < Number(amountToBuy)) {
                        setLowBalanceAlert(true);
                        return;
                    }
                    const hash = await contractApproveWriteFunction
                        (
                            {
                                address: networkContractObj.ethUsdtAddress,
                                abi: networkContractObj.ethUsdtAbi,
                                functionName: "approve",
                                args: [
                                    networkContractObj?.ethVaultAddress,
                                    parseUnits(amountToBuy.toString(), 18).toString(),
                                ],
                            });
                    const receipt = await waitForTransaction(config, { hash: hash });
                    if (receipt.status === "success") {
                        toast.success("User approved")
                    }
                    const buyHash = await contractWriteFunction(
                        {
                            address: networkContractObj.ethVaultAddress,
                            abi: networkContractObj.ethVaultAbi,
                            functionName: "buyTokenEthPay",
                            args: ["1", parseUnits(amountToBuy.toString(), 18).toString(), false],
                            value: "0"
                        }
                    );
                    const buyReceipt = await waitForTransaction(config, { hash: buyHash });
                    if (buyReceipt.status === "success") {
                        toast.success("User bought token successfully");
                    }
                } else {
                    if (tokenBalance < Number(amountToBuy)) {
                        setLowBalanceAlert(true);
                        return;
                    }
                    const buyHash = contractWriteFunction({
                        address: networkContractObj.ethVaultAddress,
                        abi: networkContractObj.ethVaultAbi,
                        functionName: "buyTokenEthPay",
                        args: ["0", "0", false],
                        value: parseUnits(amountToBuy.toString(), 18).toString()
                    }
                    );
                    const buyReceipt = await waitForTransaction(config, { hash: buyHash });
                    if (buyReceipt.status === "success") {
                        toast.success("User bought token successfully");
                    }
                }
            }
        }
        catch (error) {
            toast.error(error);
        }
    }
    const claimHandler = async () => {
        if (!address) {
            toast.error("Please connect your wallet!");
            return;
        }
        if (chainId == 56) {
            console.log("---------claim event-------------", chainId);
            const networkContractObj = getNetworkContract(chainId);
            console.log("---------claim event-------------", networkContractObj.presaleAddress);
            contractWriteFunction({
                address: networkContractObj.presaleAddress,
                abi: networkContractObj.presaleAbi,
                functionName: "claimTokens",
                args: [],
                value: "0"
            }
            );
        } else {
            toast.error("Please change chain!");
        }

    }
    const handleCurrencyButtonClick = (currency) => {
        setBuyWith(currency);
    }
    const activeButton = buttons.find((btn) => btn.active);
    const label = activeButton.name === 'CARD' ? 'USD' : activeButton.name;
    const img = activeButton.name === 'CARD' ? '/assets/usd.svg' : activeButton.img;

    const [showMore, setShowMore] = useState(false)
    return (
        <div>
            <div className="relative block" id="hero">

                <div className="  bg-[#f9b264]  p-5 border-[6px] border-black rounded-[20px]">
                    <div className="w-full flex flex-col items-center justify-center text-center">
                        <p className="text-black CCMaladroit text-bold mb-1 text-[18px] ">{t("buy_stake")}</p>
                        <div>
                            <div
                                className="flex gap-2 CCMaladroit justify-center items-center w-full counter bg-[#d88a48] border-[2px] border-black   "
                                style={{ borderRadius: '15px 15px 0 0' }}
                            >
                                {/* {endDate ? <Countdown date={endDate} renderer={renderer}></Countdown> : <Countdown date={testEndDate} renderer={renderer}></Countdown>} */}
                                {testdate ? <Countdown date={testdate} renderer={renderer}></Countdown> : <Countdown date={testdate} renderer={renderer}></Countdown>}
                            </div>
                            <p
                                className="text-black bg-[#d88a48] CCMaladroit pt-[2px] text-[11px] text-center  "
                                style={{ borderRadius: '0 0 15px 15px' }}
                            >
                                {t("next_price_increase")}
                            </p>
                        </div>
                        <p className="text-center font-semibold leading-1 text-[14px] CCMaladroit text-black mt-3 mb-1 ">
                            {/* ${(tokensSold * 0.02 / 10 ** 18).toFixed(2)} / ${Number(Number(tokenTotalBalance) * 0.02/10**18).toFixed(3)} */}
                            ${((tokensSold * 0.02 / 10 ** 18).toFixed(2)).toLocaleString()} / ${testBalance}
                        </p>
                        <div className="w-[90%] rounded-[18px] bg-[#b77741] mx-auto h-[20px] border-[2px] border-black ">
                            <div className="rounded-[20px] w-[97.6042%] bg-[#f58730] h-full  " style={{ width: `${tokensSold / 10 ** 20}%` }}  ></div>
                        </div>

                        <div className="text-[14px] CCMaladroit mb-1 mt-3 flex justify-center items-center text-black ">
                            <span className="uppercase me-1">{t("your_purchased")}</span>
                            <span className="font-semibold"> = {purchasedToken ? purchasedToken : 0}</span>
                            <img src="/assets/info-icon.svg" alt="" className="ms-2 cursor-pointer" onClick={handleOpenModal2} />
                        </div>
                        <div className="text-[14px] CCMaladroit mb-1 flex justify-center items-center text-black ">
                            <span className="uppercase me-1">{t("your_stakeable")}</span>
                            <span className="font-semibold"> = {purchasedToken ? purchasedToken : 0} </span>
                            {/* <img src="/assets/info-icon.svg" alt="" className="ms-2" /> */}
                            <img src="/assets/info-icon.svg" alt="" className="ms-2 cursor-pointer" onClick={handleOpenModal3} />
                        </div>
                    </div>
                    <div className="relative">
                        <div className="text-center text-black CCMaladroit mb-1 dashTitle">1 $PEPPE = $0.02</div>
                        {/* {showMore && */}
                        <div className="flex gap-2 items-center justify-center">
                            {buttons.map((btn, index) => (
                                <button
                                    key={btn.name}
                                    onClick={() => { handleButtonClick(index); handleCurrencyButtonClick(btn.name) }}
                                    className={`uppercase flex CCMaladroit justify-center font-semibold leading-1 gap-1 items-center py-[9px] text-[15px] md:text-[23px] min-w-[100px] lg:min-w-[110px] min-h-[40px] rounded-[30px] text-black border-[2px] ${buyWith === btn.name ? 'border-black bg-white' : 'border-transparent bg-[#bad3c180]'
                                        } transition-all hover:bg-white hover:border-black`}
                                >
                                    <img src={btn.img} className="md:w-[30px] md:h-[30px] w-[26px] h-[26px]" alt={btn.name} />
                                    <span>{btn.name}</span>
                                </button>
                            ))}
                        </div>
                        {/* } */}
                        <div className="mt-[1rem] mb-0 ">
                            <div className="mt-[.5rem] ">
                                {/* The input fields */}
                                {/* {showMore ?
                                    <div className="grid grid-cols-12 gap-[1rem] xl:my-[1.5rem]">
                                        <div className="col-span-6">
                                            <div className="flex justify-between items-center mb-1">
                                                <label htmlFor="" className="text-[13px] CCMaladroit text-black">
                                                    {t("buy_with_usd")}
                                                </label>
                                                <div className="text-[13px] text-black CCMaladroit">Max</div>
                                            </div>
                                            <div className="flex items-center relative">
                                                <input
                                                    type="number"
                                                    className="min-h-[44px] w-full py-[2px] px-[15px] text-[1rem] font-normal rounded-[30px] bg-transparent outline-none border-[2px] border-black text-black"
                                                    placeholder="0"

                                                />

                                            </div>
                                        </div> 
                                        <div className="col-span-6">
                                            <div className="flex justify-between items-center mb-1">
                                                <label htmlFor="" className="text-[13px] text-black CCMaladroit">
                                                    {t("you_receive_peppe")}
                                                </label>
                                            </div>
                                            <div className="flex items-center relative">
                                                <input
                                                    type="number"
                                                    className="min-h-[44px] w-full py-[2px] px-[15px] text-[1rem] font-normal rounded-[30px] bg-transparent outline-none border-[2px] border-black text-black"
                                                    placeholder="0"
                                                    value={receiveToken}
                                                />
                                                <div
                                                    className="absolute top-[9px] right-[.8rem] flex items-center"
                                                    style={{ translate: 'translateY(-50%)' }}
                                                >
                                                    <img src="/assets/logo-peppe.png" className="w-[28px] h-[28px]" alt="Token" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                     :
                                    <div className="grid grid-cols-12 gap-[1rem] xl:my-[1.5rem]">
                                        <div className="col-span-6">
                                            <div className="flex justify-between items-center mb-1">
                                                <label htmlFor="" className="text-[13px] CCMaladroit text-black">
                                                    {t("pay_with")} {label}{' '}
                                                </label>
                                                <div className="text-[13px] text-black CCMaladroit">Max</div>
                                            </div>
                                            <div className="flex items-center relative">
                                                <input
                                                    type="number"
                                                    className="min-h-[44px] w-full py-[2px] px-[15px] text-[1rem] font-normal rounded-[30px] bg-transparent outline-none border-[2px] border-black text-black"
                                                    placeholder="0"
                                                    value={amountToBuy}
                                                    onChange={handleInputChange}
                                                />
                                                <div
                                                    className="absolute top-[9px] right-[.8rem] flex items-center"
                                                    style={{ translate: 'translateY(-50%)' }}
                                                >
                                                    <img src={img} className="w-[28px] h-[28px]" alt={label} />
                                                </div>
                                            </div>
                                        </div>
 
                                        <div className="col-span-6">
                                            <div className="flex justify-between items-center mb-1">
                                                <label htmlFor="" className="text-[13px] text-black CCMaladroit">
                                                    {t("peppe_you_receive")}
                                                </label>
                                            </div>
                                            <div className="flex items-center relative">
                                                <input
                                                    type="number"
                                                    className="min-h-[44px] w-full py-[2px] px-[15px] text-[1rem] font-normal rounded-[30px] bg-transparent outline-none border-[2px] border-black text-black"
                                                    placeholder="0"
                                                    value={receiveToken}
                                                />
                                                <div
                                                    className="absolute top-[9px] right-[.8rem] flex items-center"
                                                    style={{ translate: 'translateY(-50%)' }}
                                                >
                                                    <img src="/assets/logo-peppe.png" className="w-[28px] h-[28px]" alt="Token" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                } */}
                                <div className="grid grid-cols-12 gap-[1rem] xl:my-[1.5rem]">
                                    <div className="col-span-6">
                                        <div className="flex justify-between items-center mb-1">
                                            <label htmlFor="" className="text-[13px] CCMaladroit text-black">
                                                {t("pay_with")} {label}{' '}
                                            </label>
                                            <div className="text-[13px] text-black CCMaladroit">Max</div>
                                        </div>
                                        <div className="flex items-center relative">
                                            <input
                                                type="number"
                                                className="min-h-[44px] w-full py-[2px] px-[15px] text-[1rem] font-normal rounded-[30px] bg-transparent outline-none border-[2px] border-black text-black"
                                                placeholder="0"
                                                value={amountToBuy}
                                                onChange={handleInputChange}
                                            />
                                            <div
                                                className="absolute top-[9px] right-[.8rem] flex items-center"
                                                style={{ translate: 'translateY(-50%)' }}
                                            >
                                                <img src={img} className="w-[28px] h-[28px]" alt={label} />
                                            </div>
                                        </div>
                                    </div>

                                    {/* Your second input field (unchanged) */}
                                    <div className="col-span-6">
                                        <div className="flex justify-between items-center mb-1">
                                            <label htmlFor="" className="text-[13px] text-black CCMaladroit">
                                                {t("peppe_you_receive")}
                                            </label>
                                        </div>
                                        <div className="flex items-center relative">
                                            <input
                                                type="number"
                                                className="min-h-[44px] w-full py-[2px] px-[15px] text-[1rem] font-normal rounded-[30px] bg-transparent outline-none border-[2px] border-black text-black"
                                                placeholder="0"
                                                value={receiveToken}
                                            />
                                            <div
                                                className="absolute top-[9px] right-[.8rem] flex items-center"
                                                style={{ translate: 'translateY(-50%)' }}
                                            >
                                                <img src="/assets/logo-peppe.png" className="w-[28px] h-[28px]" alt="Token" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {lowBalanceAlert && (
                            <p className="text-center text-white text-[14px]">
                                {t("low_balance_alert")}
                            </p>
                        )}
                        {!endDate && (
                            <p className="text-center text-white text-[14px]">
                                {t("change_network")}
                            </p>
                        )}


                        <div className="flex items-center gap-[1rem] mb-[.5rem] mt-[1.5rem] justify-center ">

                            {/* {account ?
               <button className="rounded-[80px] bg-transparent border-[2px] border-black text-[17px] font-semibold min-w-[120px] min-h-[40px] capitalize hover:bg-white hover:border-black transition-all py-[4px] px-[15px] text-white hover:text-black "
                onClick={async () => buyHandler()}
              >
                Buy
              </button> :
                <button className="rounded-[80px] bg-transparent border-[2px] border-black text-[17px] font-semibold min-w-[120px] min-h-[40px] capitalize hover:bg-white hover:border-black transition-all py-[4px] px-[15px] text-white hover:text-black "
                  onClick={handleOpenModal4}
                >Connect Wallet</button>
                // <w3m-button/>
              } */}

                            {account ? Date.now() < endDate ? <button className="rounded-[80px] bg-transparent border-[2px] border-black text-[17px] font-semibold min-w-[120px] min-h-[40px] capitalize hover:bg-white hover:border-black transition-all py-[4px] px-[15px] text-white hover:text-black "
                                onClick={async () => buyHandler()}
                            >
                                Buy
                            </button> : endDate ? <button className="rounded-[80px] bg-transparent border-[2px] border-black text-[17px] font-semibold min-w-[120px] min-h-[40px] capitalize hover:bg-white hover:border-black transition-all py-[4px] px-[15px] text-white hover:text-black "
                                onClick={async () => claimHandler()}
                            >
                                Claim
                            </button> : <button className="rounded-[80px] bg-transparent border-[2px] border-black text-[17px] font-semibold min-w-[120px] min-h-[40px] capitalize hover:bg-white hover:border-black transition-all py-[4px] px-[15px] text-white hover:text-black "

                            >
                                Loading...
                            </button> :
                                <button className="rounded-[80px] bg-transparent border-[2px] border-black text-[17px] font-semibold min-w-[120px] min-h-[40px] capitalize hover:bg-white hover:border-black transition-all py-[4px] px-[15px] text-white hover:text-black "
                                    onClick={handleOpenModal4}
                                >{t("connect_wallet")}</button>
                            }
                            {chainId === 56 ? (
                                <>
                                    <button
                                        className="rounded-[80px] bg-transparent border-[2px] flex items-center border-black text-[17px] font-semibold min-w-[120px] min-h-[40px] capitalize hover:bg-white hover:border-black transition-all py-[4px] px-[15px] text-white hover:text-black  "
                                        onClick={() => {
                                            // setConvertBtn(false);
                                            switchChain({ chainId: 1 });
                                        }}
                                    >
                                        <>
                                            <img src="/assets/ETH.svg" className="w-[18px] h-auto me-2 " alt="" />
                                            {t("buy_with_eth")}
                                        </>
                                    </button>
                                </>
                            ) : (
                                <button
                                    onClick={handleOpenModal}
                                    className="rounded-[80px] bg-transparent border-[2px] flex items-center border-black text-[17px] font-semibold min-w-[120px] min-h-[40px] capitalize hover:bg-white hover:border-black transition-all py-[4px] px-[15px] text-white hover:text-black  "
                                >
                                    <>
                                        <img src="/assets/BNB.svg" className="w-[18px] h-auto me-2 " alt="" />
                                        Buy with BNB
                                    </>
                                </button>
                            )}
                        </div>
                        <h1 className='text-center '>
                            {t("transaction_consent")}

                        </h1>
                        {/* {showMore ?
                            // <p className='text-center'>
                            //     WANT TO PAY WITH CARD INSTEAD? <span className='text-blue-500 cursor-pointer' onClick={() => { setShowMore(!showMore) }}> CLICK HERE
                            //     </span>
                            // </p>
                            ''
                            :
                            <p className="text-center uppercase">
                                {t("pay_with_crypto")} <span className="text-blue-500 cursor-pointer" onClick={() => { setShowMore(!showMore) }}>
                                    {t("click_here")}
                                </span>
                            </p>} */}
                    </div>
                </div>
            </div>

            {/* Modal */}
            <BuyPepeModal open={isModalOpen4} onClose={handleCloseModal4} />
            <Modal
                open={isModalOpen2}
                onClose={handleCloseModal2}
                desc={`   Your total purchased tokens are all tokens purchased using the connected wallet. This includes all staked and unstaked tokens.`}
            />
            <Modal
                open={isModalOpen3}
                onClose={handleCloseModal3}
                desc={`Unclaimed $Peppe that was bought using ETH or ERC-20 USDT can be staked now. Please note that all early staked tokens can be withdrawn 7 days after token claiming goes live, which will happen after the presale has concluded.`}
            />
            {isModalOpen && (
                <div
                    className="fixed z-[20] inset-0 bg-black bg-opacity-50 flex justify-center items-center"
                    onClick={handleCloseModal}
                >
                    <div
                        className="bg-[#bad3c1] p-[1.5rem] rounded-[1rem] shadow-lg w-[25em] max-w-lg"
                        onClick={(e) => e.stopPropagation()} // Prevent closing the modal when clicking inside it
                    >
                        <div className="flex flex-col items-center">
                            <img src="/assets/info.svg" className="h-[90px]" alt="" />
                            <h2 className="pt-4 text-center font-semibold text-[2rem] ">{t("confirmation_title")}</h2>
                            <div className="my-3 text-[1.125rem] font-normal text-center  " style={{ padding: '1em 1.6em .3em' }}>
                                {t("confirmation_message")}
                            </div>
                            <div className="flex gap-2">
                                <button
                                    className="mt-4 bg-[#215bb8] min-w-[5rem] min-h-[40px] text-white px-4 py-2 rounded-[30px] hover:bg-[#d11313e6] btnnn transition-all"
                                    onClick={() => {
                                        // setConvertBtn(true);
                                        handleCloseModal();
                                        switchChain({ chainId: 56 })
                                    }}
                                >
                                    {t("confirmation_buy_bsc")}
                                </button>
                                <button
                                    className="mt-4 bg-[#EFDE6C] min-w-[5rem] min-h-[40px] text-black px-4 py-2 rounded-[30px] hover:bg-[#d11313e6]   transition-all"
                                    onClick={handleCloseModal}
                                >
                                    {t("confirmation_cancel")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
